<template>
  <div>
    <Modal
      title="画面详情"
      v-model="screenDetailModal"
      width="60%"
      :footer-hide="true"
    >
    <Card
      v-if="approvalImage.length"
      dis-hover
    >
      <p slot="title">画面</p>
      <Row :gutter="8">
        <i-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-for="(image,imgIndex) in approvalImage"
          :key="imgIndex"
        >
          <a
            href="javascript:;"
            @click="handlePreview(image.fileKey)"
          >
            <img
              :src="formatImageUrl(image.fileKey,200,0)"
              style="width:100%"
            >
          </a>
        </i-col>
        <i-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-if="approvalImage.length===0"
        >
          <span>暂无可用画面</span>
        </i-col>
      </Row>
    </Card>

    <Card
      class="m-t-10"
      v-if="approvalVideo.length"
      dis-hover
    >
      <p slot="title">视频</p>
      <Row :gutter="8">
        <i-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-for="(video,videoIndex) in approvalVideo"
          :key="videoIndex"
        >
          <card
            class="text-center"
            style="word-break: break-all;word-wrap: break-word;"
          >
            <a @click="handleVideoPreview(video.fileKey)">{{video.fileName}}</a> <span class="text-green">({{video.duration}}秒)</span>
          </card>
        </i-col>
      </Row>
    </Card>
    <Card
      class="m-t-10"
      v-if="approvalVoice.length"
      dis-hover
    >
      <p slot="title">音频</p>
      <Row :gutter="8">
        <i-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-for="(voice,voiceIndex) in approvalVoice"
          :key="voiceIndex"
        >
          <card
            class="text-center"
            style="word-break: break-all;word-wrap: break-word;"
          >
            <a @click="handleVideoPreview(voice.fileKey)">{{voice.fileName}}</a> <span class="text-green">({{voice.duration}}秒)</span>
          </card>
        </i-col>
      </Row>
    </Card>

    <Card
      class="m-t-10"
      dis-hover
    >
      <p slot="title">资质文件列表</p>
      <i-table
        stripe
        :columns="approvalFileColumn"
        :data="approvalFile"
        size="small"
      ></i-table>
    </Card>

    <Card
      class="m-t-10"
      dis-hover
    >
      <p slot="title">审批记录</p>

      <template v-if="nodes.length>0">
        <span class="workplatform-title">审批进度</span>
        <Steps :current="currentStep">
          <Step
            v-for="(node,i) in nodes"
            :key="i"
            :title="node.approvalnodeName"
            :content="node.approverUserName + '['+ node.statusName + ']'"
          >
          </Step>
        </Steps>
      </template>

      <span class=" workplatform-title">已有审批记录</span>
      <i-table
        stripe
        :columns="titles"
        :data="records"
        size="small"
      ></i-table>

      <template v-if="withdrawRecords.length>0">
        <span class=" workplatform-title">撤回审批记录</span>
        <i-table
          stripe
          :columns="withdrawTitles"
          :data="withdrawRecords"
          size="small"
        ></i-table>
      </template>
    </Card>
</Modal>
    <!-- 上刊材料预览（画面、视频） -->
    <Modal
      title="画面预览"
      v-model="visibleImage"
      width="60%"
      :footer-hide="true"
    >
      <img
        :src="ImageUrl"
        v-if="visibleImage"
        style="width: 100%"
      >
    </Modal>
    <Modal
      title="音视频预览"
      v-model="visibleVideo"
      :footer-hide="true"
    >
      <video
        :src="VideoUrl"
        id="materialHistoryModalVideo"
        controls="controls"
        style="width:100%;min-height:150px;"
      >
        您的浏览器不支持预览音视频。
      </video>
    </Modal>
  </div>
</template>

<script>
import { downloadFileRequest, downloadImgRequest } from '@/utils/download'
import { formatApprovalResult } from '@/utils/tagStatus'

import { fileByApproval } from '@/api/order/orderfile'
import { getApprovalSchedule } from '@/api/approval/approvaltask'
import { getOrderProcessType } from '@/api/order/orderfileV2'

export default {
  components: {
  },
  data () {
    return {
      id: 0,
      orderId: 0,
      screenDetailModal: false,
      approvalImage: [],
      approvalVideo: [],
      approvalFile: [],
      approvalVoice: [],
      visibleImage: false,
      ImageUrl: '',
      visibleVideo: false,
      VideoUrl: '',
      withdrawRemark: '', // 撤回审批理由
      approvalFileColumn: [
        { title: '文件名称', key: 'fileName' },
        {
          title: '下载',
          width: 70,
          render: (h, params) => {
            return h('a',
              {
                style: { fontSize: '20px' },
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }

              },
              [h('Icon', { props: { type: 'md-cloud-download' } })]
            )
          }
        }
      ],
      records: [],
      titles: [
        { title: '审批人', width: 100, key: 'approverUserName' },
        { title: '审批时间', key: 'finishTime' },
        {
          title: '审批意见',
          render: (h, data) => {
            let isWarning
            if (data.row.processtype === 10 && data.row.taskSupplementFeature === 1) {
              isWarning = h('tag', { props: { color: 'blue', type: 'border' } }, data.row.taskSupplementFeatureName)
            }

            return h('div', [
              formatApprovalResult(h, data.row.approvalResult, data.row.approvalResultName),
              isWarning
            ])
          }
        },
        { title: '审批备注', key: 'comment' }
      ],
      withdrawRecords: [],
      withdrawTitles: [
        { title: '处理人', key: 'latestApproverUserName' },
        { title: '撤回时间', key: 'latestApproverFinishTime' },
        { title: '撤回理由', key: 'comment' }
      ],
      currentStep: 0,
      nodes: [], // 所有的审批节点

      modelResetApproval: false,
      deleteFileIds: [], // 删除的文件ID清单
      editApprovalFile: [],
      editApprovalFileColumn: [
        { title: '文件名称', key: 'fileName' },
        {
          title: '操作',
          width: 70,
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.deleteFileIds.push(params.row.id)
                  this.handleDelFile(params.row, 2)
                }
              }
            }, '删除')
          }
        }
      ],
      edieApprovalImgOrVideo: [],
      editApprovalImgOrVideoColumn: [
        { title: '文件名称', key: 'fileName' },
        {
          title: '操作',
          width: 70,
          render: (h, params) => {
            return h('a', {
              style: { color: '#ef4f4f' },
              on: {
                click: () => {
                  this.deleteFileIds.push(params.row.id)
                  this.handleDelFile(params.row, 1)
                }
              }
            }, '删除')
          }
        }
      ]
    }
  },
  methods: {
    showModal (id, orderId) {
      this.id = id
      this.orderId = orderId
      this.screenDetailModal = true
    },
    downloadFile (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-order/v1/orderfile/downloadfile', { fileId: file.id }, file.fileName)
    },
    getApprovalDetails () {
      const _this = this
      _this.approvalImage = []
      _this.approvalVideo = []
      _this.approvalFile = []
      _this.approvalVoice = []

      fileByApproval({ approvalId: _this.id }).then(res => {
        res.forEach(file => {
          if (file.type === 1) {
            _this.approvalImage.push(file)
          } else if (file.type === 2) {
            _this.approvalFile.push(file)
          } else if (file.type === 3) {
            _this.approvalVideo.push(file)
          } else if (file.type === 4) {
            _this.approvalVoice.push(file)
          }
        })
      })
    },
    formatImageUrl (fileUrl, width, height) {
      const widthstr = (width === 0) ? '' : ',w_' + width
      const heightstr = (height === 0) ? '' : ',h_' + height

      return fileUrl + '?x-oss-process=image/resize,m_lfit' + widthstr + heightstr
    },
    handlePreview (fileUrl) {
      this.ImageUrl = fileUrl
      this.visibleImage = true
    },
    formatImgUrl (url, imgElementId) {
      return downloadImgRequest(url, imgElementId)
    },
    handleVideoPreview (url) {
      document.getElementById('materialHistoryModalVideo').load()
      this.VideoUrl = url
      this.visibleVideo = true
    },
    getProcessDetail () {
      var node = getApprovalSchedule({
        processtype: this.processtype,
        relateId: this.id,
        includeHistoryProcessinstance: false
      }).then(data => {
        this.nodes = data
      })
      var record = getApprovalSchedule({
        processtype: this.processtype,
        relateId: this.id,
        includeHistoryProcessinstance: true
      }).then(res => {
        this.records = res.filter(item => item.status && item.status === 1)
        this.withdrawRecords = res.filter(item => item.status && item.status === 2)
      })

      Promise.all([node, record]).then(() => {
        var lastRecord = this.records[this.records.length - 1] || {}
        this.currentStep =
          this.nodes.findIndex(
            node => node.approvalnodeId === lastRecord.approvalnodeId
          ) + 1
      })
    }
  },
  created () {
    if (this.id) {
      if (this.orderId) {
        getOrderProcessType({ orderId: this.orderId }).then(res => {
          this.processtype = res
          this.getProcessDetail()
        })
      }
      this.getApprovalDetails()
    }
  },
  watch: {
    id: function () {
      if (this.id) {
        if (this.orderId) {
          getOrderProcessType({ orderId: this.orderId }).then(res => {
            this.processtype = res
            this.getProcessDetail()
          })
        }

        this.getApprovalDetails()
      }
    }
  }
}
</script>
